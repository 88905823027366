import { Component, OnInit } from '@angular/core';


export interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}

export const ROUTES: RouteInfo[] = [
    { path: '/dashboard',     title: 'Dashboard',         icon:'fas fa-tv',              class: '' },
    //{ path: '/liveview',       title: 'Live View',         icon:'fas fa-video',           class: '' },
    //{ path: '/moments',      title: 'Moments',           icon:'far fa-images',           class: '' },
    { path: '/timeline',      title: 'Timeline',          icon:'fas fa-globe',           class: '' },
    { path: '/telemetry',     title: 'Weather',           icon:'fas fa-wind',            class: '' },
    { path: '/consumption',     title: 'Consumption',           icon:'fas fa-water',            class: '' },
    { path: '/energy',        title: 'Energy',            icon:'fas fa-solar-panel',     class: '' },
    //{ path: '/support',       title: 'Support US!',       icon:'fas fa-donate',          class: 'active-pro' },
    { path: '/contact',       title: 'Talk with us!',       icon:'fas fa-envelope-square',          class: 'active-pro' }

    
];

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
    }
}

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAEVKy1jaZOR8oa1hI3XgREPYp7qcwWBBs",
    authDomain: "marmita-320015.firebaseapp.com",
    projectId: "marmita-320015",
    storageBucket: "marmita-320015.appspot.com",
    messagingSenderId: "788959082392",
    appId: "1:788959082392:web:b7aeec3595ee2615ed5ded",
    measurementId: "G-T5RT3S9VRT"
  },
  adsense: {
    adClient: 'ca-pub-3300684727139408',
    show: true
  },
  twitter: {
    apiKey: "nNAnIgPxSPlAJSx6AHmGlu9e8",
    apiSecret: "2myvHrlr8tnHw9kviKgAORaJ8EMl5UH3RgyDB0DZjg634Huq3J",
    token: "AAAAAAAAAAAAAAAAAAAAAPPMTAEAAAAA%2B27V9Ab2q3OKJTOLDpnUOplAv3g%3DJbfue5lLD7CCIGejpkqGvPx1pTUYSTiempKVPFTk33Um6EsKTD"
  }
};
